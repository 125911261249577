/** @jsxImportSource theme-ui */
import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { Box, Container, Flex, Text, Heading, Divider } from "theme-ui";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ThemedMarkdown from "../components/themed-markdown";
import { NavButtonLink } from "../components/nav-link";
import range from "../lib/range";
import { format } from "date-fns";

type PaginationPillsProps = {
  numPages: number;
  curPage: number;
};

const PaginationPills = ({ numPages, curPage }: PaginationPillsProps) => {
  return numPages > 1 ? (
    <Flex>
      {Array.from(range(numPages)).map((i: number) => (
        <Box key={i} mx={1} my={3}>
          <NavButtonLink
            to={i == 0 ? "/wissenwertes" : `/wissenwertes/${i + 1}`}
            active={curPage == i + 1}
          >
            {i + 1}
          </NavButtonLink>
        </Box>
      ))}
    </Flex>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

type BlogPageProps = {
  data: {
    allMarkdownRemark: any;
  };
  pageContext: {
    limit: number;
    skip: number;
    numPages: number;
    currentPage: number;
  };
  location: string;
};

export const BlogPage = ({ data, pageContext, location }: BlogPageProps) => {
  const { edges } = data.allMarkdownRemark;
  return (
    <Layout
      activeNav="wissenwertes"
      seo={{
        title:
          pageContext.currentPage == 1
            ? "Wissenwertes"
            : `Wissenwertes - Seite ${pageContext.currentPage}`,
        pathname: location,
      }}
    >
      <Container my={5}>
        <Heading as="h1" variant="styles.h1">
          Wissenwertes
        </Heading>

        <PaginationPills numPages={pageContext.numPages} curPage={pageContext.currentPage} />
        {edges.map((e, i) => {
          const frontmatter = e.node.frontmatter;
          const imageData = getImage(frontmatter.featuredimage);
          return (
            <Box key={e.node.fields.slug}>
              <Heading as="h2" variant="styles.h2" sx={{ color: "primary" }}>
                {frontmatter.title}
              </Heading>
              {/* <Flex sx={{ justifyContent: "space-between" }}>
                <Text variant="smallText">
                  {format(new Date(Date.parse(frontmatter.date)), "dd.MM.yyyy")}
                </Text>
                <Link to={e.node.fields.slug}>[Direktlink]</Link>
              </Flex> */}
              <Flex sx={{ flexWrap: "wrap", flexDirection: ["column", "column", "row"] }}>
                {imageData ? (
                  <Box sx={{ flex: "1 1 0" }} mt={3}>
                    <GatsbyImage image={imageData} alt={frontmatter?.featuredimagealt || ""} />
                  </Box>
                ) : (
                  <Box sx={{ flex: "1 1 0" }} />
                )}
                <Box sx={{ flex: "0.05 0.05 0" }}></Box>
                <ThemedMarkdown sx={{ flex: "2 2 0" }} html={frontmatter.body} />
              </Flex>
              {i < pageContext.limit - 1 && <Divider mt={4} mb={4} />}
            </Box>
          );
        })}
        <PaginationPills numPages={pageContext.numPages} curPage={pageContext.currentPage} />
      </Container>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogPageTemplate($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 720, quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
            featuredimagealt
            body
          }
        }
      }
    }
  }
`;
